import React, { useState, useContext, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"

import { Formik, Form } from "formik"

import AuthContext from "../../context/auth/auth-context"
import CartContext from "../../context/cart/cart-context"

import BlurLoader from "../reusable-components/loaders/blur-loader"
import LinkWrapper from "../reusable-components/link-wrapper"

import AccountsFormInputField from "./accounts-form-input-field"
import Error from "./error"
import ShowOnButton from "./show-on-button"

import SubmitButton from "../reusable-components/buttons/submit-button"

// After logging in, never redirect back to these pages
const redirectBlacklist = ["/autres/", "/app/"]

// ================
// 	  COMPONENT
// ================

const Login = ({ location }) => {
  const [loading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [apiError, setApiError] = useState(false)
  const { performLogin, loggedInState } = useContext(AuthContext)
  const { getCart } = useContext(CartContext)

  const submitHandler = ({ email, password }) => {
    performLogin(email, password, setLoading, setApiError, getCart)
  }

  // Function that checks if user is logged in and redirects if true
  const loginCheck = () => {
    if (loggedInState === true) {
      console.log("Logged in")

      // We check if a fromUrl was passed from wherever the user clicked on "Log in"
      // But if the fromUrl contains one of the redirectBlacklist URL's, we don't use it
      if (
        location.state &&
        location.state.fromUrl &&
        !redirectBlacklist.some((badurl) =>
          location.state.fromUrl.includes(badurl)
        )
      ) {
        navigate(`${location.state.fromUrl}`, {
          replace: true,
          state: { showNotice: "You have been logged in" },
        })
      } else {
        navigate(`/my-account/`, {
          replace: true,
          state: { showNotice: "You have been logged in" },
        })
      }
    }
  }

  useEffect(() => {
    loginCheck()
  }, [loggedInState])

  return (
    <LoginPanelContainer>
      <BlurLoader
        loading={loading || loggedInState === null}
        message="Logging in"
      >
        <LoginFormSectionStyling>
          <h2>Login</h2>
          {apiError && <Error error={apiError.content} />}

          <Formik
            initialValues={{ email: "", password: "" }}
            validate={validate}
            onSubmit={submitHandler}
          >
            {({ isSubmitting, errors, touched }) => (
              <>
                <Form>
                  <AccountsFormInputField
                    label="Email"
                    type="email"
                    name="email"
                    placeholder="jane@doe.com"
                    errors={errors}
                    touched={touched}
                  />
                  <AccountsFormInputField
                    label="Password"
                    type={hidePassword ? "password" : "text"}
                    hidePassword={hidePassword}
                    autoComplete="new-password"
                    name="password"
                    errors={errors}
                    touched={touched}
                    toggle={() => {
                      setHidePassword((prevState) => !prevState)
                    }}
                  />
                  <StyledButton>
                    <SubmitButton text="LOGIN" disabled={loading} />
                  </StyledButton>
                </Form>
                <Extra>
                  New here? <LinkWrapper to="/register/">Register</LinkWrapper>
                </Extra>
                <Extra>
                  <LinkWrapper to="/reset/">Forgot Password?</LinkWrapper>
                </Extra>
              </>
            )}
          </Formik>
          <ShowOnButton />
        </LoginFormSectionStyling>
      </BlurLoader>
    </LoginPanelContainer>
  )
}

// ===============
//     STYLES
// ===============
const LoginPanelContainer = styled.div`
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .show-on {
    margin-top: 20px;
  }
`
const LoginFormSectionStyling = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  .half-width-input input {
    display: block;
  }
  label {
    width: 100%;
    display: inline-block;
    margin-top: 20px;
  }
  .half-width-input {
    max-width: 400px;
    width: 100%;
    display: inline-block;
  }
  & form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media (max-width: ${({ theme }) => theme.breakMedium}) {
    .half-width-input {
      width: 48%;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .half-width-input {
      width: 100%;
    }
    & form > div {
      width: 100%;
    }
    & form > div button {
      width: 100%;
    }
  }
`
const StyledButton = styled.div`
  margin: 20px 0;
  width: 100%;
  button {
    width: 100%;
  }
`
const Extra = styled.div`
  margin: 20px auto;
  color: ${({ theme }) => theme.colors.black1};
  text-align: center;
  a {
    color: ${({ theme }) => theme.colors.black1};
    text-decoration: underline;
  }
`

// ===============
// 	   HELPERS
// ===============
const validate = ({ email, password }) => {
  const errors = {}
  const addIf = (pred, prop, message) => pred && (errors[prop] = message)

  addIf(!email, "email", "Email is required")
  addIf(!/\S+@\S+\.\S+/.test(email), "email", "Email is invalid")
  addIf(!password, "password", "Password is required")
  addIf(
    password.length < 6,
    "password",
    "Password should be at least 6 characters"
  )

  return errors
}

export default Login
